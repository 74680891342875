import React, { useState } from "react";

const QuestionsOptions = ({ values, setValues }) => {
  const [mainInput, setMainInput] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownInput, setDropdownInput] = useState("");

  const handleMainInputClick = () => {
    setDropdownVisible(true);
  };

  const handleAddValue = () => {
    if (dropdownInput.trim()) {
      const newValues = [...values, dropdownInput];
      setValues(newValues);
      setDropdownInput("");
      setMainInput(newValues.join(", "));
    }
  };
  const handleDelete = (indexToDelete) => {
    setValues((prevValues) =>
      prevValues.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <div className="dropdown-input">
      {values?.length > 0 && (
        <div className="selected-options">
          {values?.map((item, i) => (
            <div key={i} className="selected-option">
              <p>{item}</p>
              <button onClick={() => handleDelete(i)}>X</button>
            </div>
          ))}
        </div>
      )}
      {/* {dropdownVisible && ( */}
      <div className="d-flex p-1" style={{ gap: "10px" }}>
        <input
          type="text"
          value={dropdownInput}
          className="form-control br-0"
          onChange={(e) => setDropdownInput(e.target.value)}
          placeholder="Enter a value"
        />
        {values?.length < 4 && (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleAddValue}
          >
            Add
          </button>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default QuestionsOptions;
